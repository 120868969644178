import React, { useState, useEffect } from 'react';
import ProductItem from '../components/ProductItem';
import User from '../api/User';
import Helpers from '../components/Helpers';
function MyProducts() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const addProduct = () => {
        const p = [...products];
        const newProduct = {
            id: 0,
            index: Math.ceil(Math.random() * 100000),
            title: "untitled",
            border: 0,
            fineart_paper: true,
            photo_paper: true,
            photorag_paper: true,
            margin: 20,
            is_approved: false,
            status: 'processing',
            created_at: Helpers.renderNowToMysql(),
            limited_copies: 0
        }
        p.push(newProduct);
        setProducts(p);
    }

    const productItemCancel = () => {
        const completedProducts = products.filter((item) => {
            return item.id !== 0;
        });
        setProducts(completedProducts);
    }

    const productFinishedEditing = () => {
        loadProducts();
    }

    useEffect(() => {
        loadProducts();
    }, [])

    const loadProducts = () => {
        setIsLoading(true);
        User.get_products().then((res) => {
            setProducts(res.data);
            setIsLoading(false);
        });
    }

    if (isLoading) {
        return (
            <React.Fragment>
                <h1>My Products</h1>
                <p>loading...</p>
            </React.Fragment >
        )
    } else if (products.length === 0) {
        return (
            <React.Fragment>
                <h1>My Products</h1>
                <div className="panel"><button onClick={() => addProduct()}>Add Product</button></div>
                <p>start by adding some products</p>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <h1>My Products</h1>
                <div className="panel"><button onClick={() => addProduct()}>Add Product</button></div>
                <br /><br />
                <div id="myproducts">
                    {products.map((item, index) => {
                        return (
                            <ProductItem productFinishedEditing={productFinishedEditing} cancel={productItemCancel} key={index} data={item}></ProductItem>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

}

export default MyProducts;