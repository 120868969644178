import React, { useState, useEffect } from 'react';
import { Radio } from 'semantic-ui-react';
import User from '../api/User';

function MyAccount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const auth = user.auth;
    const profile = user.profile ? user.profile : {};
    const [editPassword, setEditPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [errors, setErrors] = useState([]);
    const error_password = errors.password ? <div><span className="error">{errors.password[0]}</span></div> : null;
    const payments = (Object.keys(profile).length === 0 && profile.constructor === Object) || profile.payment_settings == null
        ? {}
        : JSON.parse(profile.payment_settings);
    const [dataStatus, setDataStatus] = useState("saved");
    // profile
    const [fullname, setFullname] = useState(profile.fullname);
    const [country, setCountry] = useState(profile.country);
    const [address, setAddress] = useState(profile.address);
    const [phone, setPhone] = useState(profile.phone);
    const [bio, setBio] = useState(profile.bio);
    // payments
    const [selectedPaymentId, setSelectedPaymentId] = useState(profile.selected_payment_id);
    const [paypalEmail, setPaypalEmail] = useState(payments.paypalEmail);
    const [beneficiary, setBeneficiary] = useState(payments.beneficiary);
    const [iban, setIban] = useState(payments.iban);
    const [accountNumber, setAccountNumber] = useState(payments.accountNumber);
    const [bankName, setBankName] = useState(payments.bankName);
    const [swiftCode, setSwiftCode] = useState(payments.swiftCode);

    let emailTag;
    let dataStatusJsx;

    useEffect(() => {
        setStatusJsx();
    }, [dataStatus])

    useEffect(() => {
        saveSettings(true);
    }, [selectedPaymentId])

    const setStatusJsx = () => {
        switch (dataStatus) {
            case "saving":
                dataStatusJsx = <div id="data-status" className="saving">saving settings...</div>;
                break;
            case "dirty":
                dataStatusJsx = <div id="data-status" className="dirty">unsaved data</div>;
                break;
            case "saved":
            default:
                dataStatusJsx = <div id="data-status" className="saved">settings saved</div>;
                break;
        }
    }

    if (auth.email_verified_at == null) {
        emailTag = (<span title="email not verified">
            <span>{auth.email}</span>&nbsp;<i style={{ color: 'red' }} className="fas fa-times-circle"></i>
        </span>);
    } else {
        emailTag = (<span title="email verified">
            <span>{auth.email}</span>&nbsp;<i style={{ color: 'green' }} className="fas fa-check-circle"></i>
        </span>);
    }

    const saveSettings = async (force = false) => {
        if (force === false && dataStatus !== "dirty") return;

        setDataStatus("saving");

        let settings = {};
        let paymentSettings = {};

        paymentSettings.paypalEmail = paypalEmail;
        paymentSettings.beneficiary = beneficiary;
        paymentSettings.iban = iban;
        paymentSettings.accountNumber = accountNumber;
        paymentSettings.bankName = bankName;
        paymentSettings.swiftCode = swiftCode;

        settings.fullname = fullname;
        settings.country = country;
        settings.address = address;
        settings.phone = phone;
        settings.bio = bio;
        settings.selected_payment_id = selectedPaymentId;
        settings.payment_settings = JSON.stringify(paymentSettings);

        await User.save_settings(settings);

        setDataStatus("saved");

    }

    const updatePassword = async () => {
        setDataStatus("saving");
        await User.update_password({ password: newPassword, password_confirmation: newPasswordConfirmation })
            .then((res) => {
                setEditPassword(false);
                setNewPassword();
                setNewPasswordConfirmation();
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
        setDataStatus("saved");
    }

    const passwordEditForm =
        <React.Fragment>
            <br /><br />
            <label htmlFor="new-password">New password</label><br />
            <input type="password" id="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} autoComplete="new_password" /><br />
            {error_password}
            <label htmlFor="password_confirmation">Password confirm</label><br />
            <input type="password" id="password_confirmation" value={newPasswordConfirmation} onChange={(e) => setNewPasswordConfirmation(e.target.value)} autoComplete="new_password"></input><br />
            <button className="faded" onClick={() => updatePassword()}>Change</button>&nbsp;&nbsp;
            <button onClick={() => { setEditPassword(false); setNewPassword(); setNewPasswordConfirmation(); }}>Cancel</button>
        </React.Fragment>

    setStatusJsx();

    return (
        <React.Fragment>
            <h1>Settings</h1>
            {dataStatusJsx}
            <div id="myaccount">
                <div>
                    <h2>Contact</h2>
                    <label htmlFor="fullname">Fullname</label><br />
                    <input id="fullname" type="text" value={fullname} onChange={(e) => { setFullname(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="country">Country</label><br />
                    <input id="country" type="text" value={country} onChange={(e) => { setCountry(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="address">Address</label><br />
                    <input id="address" type="text" value={address} onChange={(e) => { setAddress(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="phone">Mobile</label><br />
                    <input id="phone" type="text" value={phone} onChange={(e) => { setPhone(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input>
                </div>
                <div>
                    <h2>Payments</h2>
                    <Radio
                        label='PayPal'
                        name='radioGroup'
                        value='1'
                        checked={selectedPaymentId === 1}
                        onChange={() => { setSelectedPaymentId(1); }}
                    />
                    <label htmlFor="paypal_email">Email:</label><br />
                    <input id="paypal_email" type="email" value={paypalEmail} onChange={(e) => { setPaypalEmail(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input>
                    <br /><br />
                    <Radio
                        label='Bank Transfer'
                        name='radioGroup'
                        value='2'
                        checked={selectedPaymentId === 2}
                        onChange={() => { setSelectedPaymentId(2); }}
                    />
                    <label htmlFor="beneficiary">Beneficiary</label><br />
                    <input id="beneficiary" type="text" value={beneficiary} onChange={(e) => { setBeneficiary(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="iban">IBAN</label><br />
                    <input id="iban" type="text" value={iban} onChange={(e) => { setIban(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="account_number">Account Number</label><br />
                    <input id="account_number" type="text" value={accountNumber} onChange={(e) => { setAccountNumber(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="bank_name">Bank Name</label><br />
                    <input id="bank_name" type="text" value={bankName} onChange={(e) => { setBankName(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input><br />
                    <label htmlFor="swift_code">Swift Code</label><br />
                    <input id="swift_code" type="text" value={swiftCode} onChange={(e) => { setSwiftCode(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></input>
                </div>
                <div>
                    <h2>Account</h2>
                    <span>{emailTag}</span><br /><br />
                    <span className="likeA" onClick={() => setEditPassword(true)}>Change Password</span>
                    {editPassword ? passwordEditForm : null}
                    <h2>Bio</h2>
                    <textarea rows="10" style={{ width: "100%" }} value={bio} onChange={(e) => { setBio(e.target.value); setDataStatus("dirty"); }} onBlur={() => saveSettings()}></textarea>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MyAccount;