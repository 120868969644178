import React, { useState, useEffect, useRef } from 'react';
import Helpers from './Helpers';
import Products from '../api/Products';
function ProductItem(props) {
    const { id, index, filename, image_thumb_url, width, height, title, description, border, fineart_paper, photo_paper, photorag_paper, margin, limited_copies, notes, status, created_at } = props.data;
    const dataSavingDataStatusJsx = <div><small id="productdata-status" className="saving">saving product...</small></div>;
    const dataDirtyDataStatusJsx = <div><small id="productdata-status" className="dirty">unsaved data</small></div>;
    const dataSavedDataStatusJsx = <div><small id="productdata-status" className="saved">product saved</small></div>;
    const [errors, setErrors] = useState([]);
    const [dataStatusJsx, setDataStatusJsx] = useState(dataSavedDataStatusJsx);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataStatus, setDataStatus] = useState("saved");
    const [productId, setProductId] = useState(id);
    const [filenameEdit, setFilenameEdit] = useState(filename);
    const [titleEdit, setTitleEdit] = useState(title);
    const [descriptionEdit, setDescriptionEdit] = useState(description);
    const [borderEdit, setBorderEdit] = useState(border);
    const [fineart_paperEdit, setFineart_paperEdit] = useState(fineart_paper);
    const [photo_paperEdit, setPhoto_paperEdit] = useState(photo_paper);
    const [photorag_paperEdit, setPhotorag_paperEdit] = useState(photorag_paper);
    const [marginEdit, setMarginEdit] = useState(margin);
    const [limited_copiesEdit, setLimited_copiesEdit] = useState(limited_copies);
    const [notesEdit, setNotesEdit] = useState(notes);
    const elementsIndex = index ? index : id;
    const errorImageJsx = errors.product ? <div className="error" style={{ marginTop: "10px" }}><span>{errors.product}</span></div> : null;
    const isMounted = useRef(false);

    useEffect(() => {
        if (productId === 0) {
            setIsEditMode(true)
        }
        if (dataStatus === "dirty") updateProduct();
    }, [])

    useEffect(() => {
        setStatusJsx();
    }, [dataStatus])

    useEffect(() => {
        if (isMounted.current) {
            updateProduct(true);
        } else {
            isMounted.current = true;
        }
    }, [fineart_paperEdit, photo_paperEdit, photorag_paperEdit])

    const setStatusJsx = () => {
        switch (dataStatus) {
            case "saving":
                setDataStatusJsx(dataSavingDataStatusJsx);
                break;
            case "dirty":
                setDataStatusJsx(dataDirtyDataStatusJsx);
                break;
            case "saved":
            default:
                setDataStatusJsx(dataSavedDataStatusJsx);
                break;
        }
    }
    const createProduct = async (e) => {
        setIsLoading(true);
        const formdata = new FormData();
        const file = e.target.files[0];
        formdata.append('image', file)
        formdata.append('filename', file.name);
        formdata.append('title', title ? title : "untitled");
        formdata.append('description', description ? description : "");
        formdata.append('border', border ? border : 0);
        formdata.append('fineart_paper', fineart_paper);
        formdata.append('photo_paper', photo_paper);
        formdata.append('photorag_paper', photorag_paper);
        formdata.append('margin', margin ? margin : 0);
        formdata.append('limited_copies', limited_copies ? limited_copies : 0);
        formdata.append('notes', notes ? notes : "");
        formdata.append('status', status);

        await Products.create(formdata)
            .then((res) => {
                setFilenameEdit(file.name)
                setProductId(res.data.id);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response && (error.response.status === 415 || error.response.status === 400) && error.response.data.errors) {
                    setErrors(error.response.data.errors);
                    setIsLoading(false);
                }
            })
    }
    const updateProduct = async (force = false) => {
        if (force === false && dataStatus !== "dirty") return;
        setDataStatus("saving");
        const data = {
            id: productId,
            title: titleEdit ? titleEdit : "untitled",
            description: descriptionEdit ? descriptionEdit : null,
            border: borderEdit ? borderEdit : 0,
            fineart_paper: fineart_paperEdit,
            photo_paper: photo_paperEdit,
            photorag_paper: photorag_paperEdit,
            margin: marginEdit ? marginEdit : 20,
            limited_copies: limited_copiesEdit ? limited_copiesEdit : 0,
            notes: notesEdit ? notesEdit : null
        }
        await Products.update(data)
            .then((res) => {
                setDataStatus("saved");
            })

    }

    if (isLoading) {
        return (
            <div className="product-item"><p>loading...</p></div>
        )
    } else if (isEditMode && filenameEdit === undefined) {
        return (
            <div>
                <div className="product-item editing">
                    <label htmlFor="file_uploader">First, upload the artwork / photo</label><br />
                    <small>only jpg format, minimum resolution 2000px</small>
                    <br />
                    <br />
                    < input id="file_uploader" type="file" onChange={(e) => createProduct(e)} accept="image/jpeg"></input>
                    {errorImageJsx}
                    <div style={{ textAlign: 'right' }}><span onClick={(e) => props.cancel(productId)} className="likeA">cancel</span></div>
                </div>
            </div >
        )
    } else if (isEditMode && filenameEdit !== undefined) {
        return (
            <div className="product-item editing">
                <div className="editing-fields">
                    <span>Image</span>
                    <div>{filenameEdit}</div>
                    <span>Title</span>
                    <input type="text" value={titleEdit} onChange={(e) => { setTitleEdit(e.target.value); setDataStatus("dirty"); }} onBlur={() => { updateProduct() }} />
                    <span>Description</span>
                    <textarea rows="4" value={descriptionEdit} onChange={(e) => { setDescriptionEdit(e.target.value); setDataStatus("dirty"); }} onBlur={() => { updateProduct() }} ></textarea>
                    <span>Profit Margin %</span>
                    <input type="text" value={marginEdit} onChange={(e) => { setMarginEdit(e.target.value); setDataStatus("dirty"); }} onBlur={() => { updateProduct() }} />
                </div>
                <br />
                <input type="checkbox" id={`chkFap${elementsIndex}`} checked={fineart_paperEdit ? "checked" : ""} onChange={() => { setFineart_paperEdit(!fineart_paperEdit); }} />
                <label htmlFor={`chkFap${elementsIndex}`}>Enhanced Matte Art <small>200gsm</small></label><br />
                <input type="checkbox" id={`chkPhp${elementsIndex}`} checked={photo_paperEdit ? "checked" : ""} onChange={() => { setPhoto_paperEdit(!photo_paperEdit); }} />
                <label htmlFor={`chkPhp${elementsIndex}`} >Lustre Photo Paper <small>240gsm</small></label><br />
                <input type="checkbox" id={`chkPrp${elementsIndex}`} checked={photorag_paperEdit ? "checked" : ""} onChange={() => { setPhotorag_paperEdit(!photorag_paperEdit); }} />
                <label htmlFor={`chkPrp${elementsIndex}`} >Hahnemühle Photo Rag <small>308gsm</small></label>
                <br /><br />
                <div className="editing-fields">
                    <span>Border <small>cm</small></span>
                    <input type="text" value={borderEdit} onChange={(e) => { setBorderEdit(e.target.value); setDataStatus("dirty"); }} onBlur={() => { updateProduct() }} />
                    <span>Limited Copies</span>
                    <input type="text" value={limited_copiesEdit} onChange={(e) => { setLimited_copiesEdit(e.target.value); setDataStatus("dirty"); }} onBlur={() => { updateProduct() }} />
                    <span>Notes</span>
                    <textarea rows="3" value={notesEdit} onChange={(e) => { setNotesEdit(e.target.value); setDataStatus("dirty"); }} onBlur={() => { updateProduct() }} ></textarea>
                    <span>Created at</span>
                    <div>{Helpers.renderMySqlToUs(created_at)}</div>
                </div>
                <br />
                <div className="editing-footer">
                    {dataStatusJsx}
                    <div style={{ textAlign: 'right' }}><span onClick={(e) => { setIsEditMode(false); props.productFinishedEditing() }} className="likeA">finish</span></div>
                </div>
            </div >
        )
    } else {
        return (
            <div className="product-item">
                <p className="title" title="Product Title">{title}</p>
                <div className="product-body">
                    <div className="image-holder" title="Product Image"><img src={image_thumb_url} alt="your artwork"></img></div>
                    <div className="product-info">
                        <div className="margin" title="Sales Margin">{margin}%</div>
                        <div className="papers-selection">
                            <div className={fineart_paper ? "paper-selected" : "paper-inactive"} title="Enhanced Matte Art (EMA) 200gsm" ><span >EMA</span></div>
                            <div className={photo_paper ? "paper-selected" : "paper-inactive"} title="Lustre Photo Paper (LPP) 240gsm"><span >LPP</span></div>
                            <div className={photorag_paper ? "paper-selected" : "paper-inactive"} title="Hahnemühle Photo Rag (HPR) 308gsm"><span>HPR</span></div>
                        </div>
                        <div className="date-created" title="Date Created">{Helpers.renderMySqlToUs(created_at)}</div>
                        <div className="dimensions" title="Image dimensions in pixels (width x height)"><small>{width} x {height}</small></div>
                        <div className="status" title="Status"><small>{status}</small></div>
                    </div>
                </div>
                <br />
                <div style={{ textAlign: 'right' }}><span className="likeA" onClick={() => setIsEditMode(true)}>edit</span></div>
            </div>
        )
    }
}

export default ProductItem;