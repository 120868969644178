import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async register(form) {
        await Csrf.getCookie();

        return Api.post("/register", form);
    },

    async login(form) {
        await Csrf.getCookie();

        return Api.post("/login", form);
    },

    async logout() {
        await Csrf.getCookie();

        return Api.post("/logout");
    },
    async accepted_agreements() {
        await Csrf.getCookie();
        return Api.get("/user/accepted_agreements");
    },
    async accept_agreement(id) {
        await Csrf.getCookie();
        return Api.post("/user/accept_agreement", { id });
    },
    async save_settings(settings) {
        await Csrf.getCookie();
        return Api.post("/user/settings", settings);
    },
    async update_password(password) {
        await Csrf.getCookie();
        return Api.put("/user/password", password);
    },
    async get_products() {
        await Csrf.getCookie();
        return Api.get("/user/products");
    },
};
