import React, { useEffect, useState } from 'react';
import Tools from '../api/Tools';
function MarginsCalculator() {
    const [pricelist, setPricelist] = useState(false);
    const [margin, setMargin] = useState(20);
    useEffect(() => {
        Tools.pricelist().then((res) => {
            setPricelist(res.data)

        });
    }, [])

    const renderTableData = () => {
        return pricelist.map((item, index) => {
            const { Inches, Centimeters, Fine_Art_Print, Photo_Art_Print, Hahnemühle_German_Etching_Print, Framed_Print, Framed_And_Mounted } = item
            return (
                <div className="calc-row" key={index}>
                    <span>{index + 1}</span>
                    <span>{Inches}</span>
                    <span>{Centimeters}</span>
                    <span>{getPriceWithMarginText(Fine_Art_Print)}</span>
                    <span>{getPriceWithMarginText(Photo_Art_Print)}</span>
                    <span>{getPriceWithMarginText(Hahnemühle_German_Etching_Print)}</span>
                    <span>{getPriceWithMarginText(Framed_Print)}</span>
                    <span>{getPriceWithMarginText(Framed_And_Mounted)}</span>
                </div>
            )
        })
    }
    const getPriceWithMarginText = (price) => {
        if (!price) return "-";
        const finalprice = parseFloat(price * ((100 + margin) / 100)).toFixed(2)
        const earnings = parseFloat(finalprice - parseFloat(price)).toFixed(2);
        return (<span>{finalprice}&euro; <small style={{ color: "gray" }}>(+{earnings}&euro;)</small></span>)
    }
    const getMarginInputValue = (value) => {
        value = parseInt(value);
        if (isNaN(value)) return 0;
        return value
    }
    if (pricelist) {
        return (
            <React.Fragment>
                <h1>Margins Calculator</h1>
                <div id="margins_calculator">
                    <div id="margin-input">
                        <label htmlFor="margin">Enter desired margin</label>&nbsp;
                    <input type="text" value={margin} onChange={(e) => { setMargin(getMarginInputValue(e.target.value)) }} placeholder="e.g. 20"></input><strong>%</strong>&nbsp;&nbsp;
                    <small>*There might be a slight differece from the actual selling prices due to decimal rounding</small>
                    </div>
                    <br /><br />
                    <div className="calc-row header">
                        <span></span>
                        <span>Inches</span>
                        <span>Centimeters</span>
                        <span>Fine Art Print</span>
                        <span>Photo Art Print</span>
                        <span>Hahnemühle German Etching Print</span>
                        <span>Framed Print</span>
                        <span>Framed &amp; Mounted</span>
                    </div>
                    {renderTableData()}
                </div >
            </React.Fragment>
        )
    } else {
        return (
            <div id="margins_calculator">
                <h1>Margins Calculator</h1>
                <p>loading...</p>
            </div>
        )
    }
}

export default MarginsCalculator;