import React, { useState, useEffect } from 'react';
import './App.css';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";

import Login from './pages/Login';
import Logout from './pages/Logout';
import Home from './pages/Home';
import Register from './pages/Register';
import MarginsHowTo from './pages/MarginsHowTo';
import MarginsCalculator from './pages/MarginsCalculator';
import Agreements from './pages/Agreements';
import MyAccount from './pages/MyAccount';
import MyProducts from './pages/MyProducts';
import PrintMaterials from './pages/PrintMaterials';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import { Context } from './components/Context';
import Logo from './images/logo64x64.png';



function App() {
  const [context, setContext] = useState();
  const [header, setHeader] = useState(null);
  const onUserStateChanged = () => {
    _setHeader();
  }
  useEffect(() => {
    _setHeader();
  }, []);
  const _setHeader = async () => {
    let user = localStorage.getItem("user");
    if (user) {
      setHeader(
        <React.Fragment>
          <header>
            <div id="header">
              <NavLink to="/"><img src={Logo} className="logo" alt="logo"></img></NavLink>
              <ul id="top-menu">
                <li><NavLink to="/myproducts">My Products</NavLink></li>
                <li><NavLink to="/agreements">Agreements</NavLink></li>
                <li><NavLink to="/myaccount">Settings</NavLink></li>
              </ul>
            </div>
            <div id="subheader">
              <div >
                <NavLink className="logout" to="/logout">Logout</NavLink>
              </div>
              <div >
                <ul id="top-submenu">
                  <li><NavLink to="/marginscalculator">Margins Calculator</NavLink></li>
                  <li><NavLink to="/marginshowto">Margins How To</NavLink></li>
                  <li><NavLink to="/printmaterials">Print Materials</NavLink></li>
                </ul>
              </div>
            </div>
          </header>
        </React.Fragment >
      );
    } else {
      setHeader("");
    }
  }
  return (
    <Context.Provider value={[context, setContext]}>
      <HashRouter>
        {header}
        <div id="content">
          <AuthenticatedRoute exact path="/"><Home /></AuthenticatedRoute>
          <AuthenticatedRoute path="/logout"><Logout onUserStateChanged={onUserStateChanged} /></AuthenticatedRoute>
          <Route path="/login"><Login onUserStateChanged={onUserStateChanged} /></Route>
          <Route path="/register" ><Register /></Route>
          <AuthenticatedRoute path="/marginshowto" ><MarginsHowTo /></AuthenticatedRoute>
          <AuthenticatedRoute path="/marginscalculator" ><MarginsCalculator /></AuthenticatedRoute>
          <AuthenticatedRoute path="/agreements" ><Agreements /></AuthenticatedRoute>
          <AuthenticatedRoute path="/myaccount" ><MyAccount /></AuthenticatedRoute>
          <AuthenticatedRoute path="/myproducts" ><MyProducts /></AuthenticatedRoute>
          <AuthenticatedRoute path="/printmaterials" ><PrintMaterials /></AuthenticatedRoute>
        </div>
        <footer><span>version {process.env.REACT_APP_VERSION}</span></footer>
      </HashRouter>
    </Context.Provider>
  )
}

export default App;
