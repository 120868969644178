import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async create(data) {
        await Csrf.getCookie();
        return Api.post("/product", data);
    },
    async upload_image(image) {
        await Csrf.getCookie();
        return Api.post("/product/image", image);
    },
    async update(data) {
        await Csrf.getCookie();
        return Api.put("/product", data);
    },
    async delete(data) {
        await Csrf.getCookie();
        return Api.delete("/product", data);
    },
}