import React from "react";
import {
    Route,
    Redirect
} from "react-router-dom";


function AuthenticatedRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={() =>
                localStorage.getItem("user") ? children : <Redirect to={{ pathname: "/login" }} />
            }
        />
    );
}

export default AuthenticatedRoute;