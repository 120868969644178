export default {
    renderMySqlToUs(date) {
        // Split timestamp into [ Y, M, D, h, m, s ] 2020-05-19T08:12:53.000000Z
        const t = date.split(/[-T:.]/);
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        return d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate();
    },
    renderNowToMysql() {
        // 2020-05-19T08:12:53.000000Z        
        const now = new Date();
        return `${now.getFullYear()}-${now.getMonth()}-${now.getDay()}T${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.000000Z`;
    }
}