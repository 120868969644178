import React, { useState } from "react";
import User from '../api/User';
import { Redirect } from "react-router-dom";


function Logout({ onUserStateChanged }) {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [redirectToHome, setRedirectToHome] = useState(false);
    const logout = () => {
        User.logout().then(() => {
            localStorage.removeItem("user");
            setRedirectToLogin(true);
            onUserStateChanged();
        });
    }
    if (redirectToLogin) {
        return (
            <Redirect to='/login'></Redirect>
        )
    } else if (redirectToHome) {
        return (
            <Redirect to='/'></Redirect>
        )
    } else {
        return (
            <div>
                <h1>Loggin out...</h1>
                <p>Are you sure?</p>
                <button onClick={logout}>Yes</button>&nbsp;&nbsp;
                <button onClick={() => setRedirectToHome(true)}>No</button>
            </div>
        )
    }
}

export default Logout;