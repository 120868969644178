import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async pricelist(form) {
        await Csrf.getCookie();
        return Api.get("/pricelist");
    },
    async agreements() {
        await Csrf.getCookie();
        return Api.get("/agreements");
    },
}