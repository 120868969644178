import React, { useState } from "react";
import User from '../api/User';
import { Redirect, NavLink } from "react-router-dom";
import BigLogo from '../images/logo801x801.png';

function Login({ onUserStateChanged }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [redirectToHome, setRedirectToHome] = useState(false);
    const error_email = errors.email ? <div><span className="error">{errors.email[0]}</span></div> : null;
    const error_password = errors.password ? <div><span className="error">{errors.password[0]}</span></div> : null;
    const onSubmit = async (e) => {
        e.preventDefault();
        User.login({ email, password })
            .then((res) => {
                localStorage.setItem("user", JSON.stringify(res.data));
                setRedirectToHome(true);
                onUserStateChanged();
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }
    if (redirectToHome) {
        return (
            <Redirect to='/'></Redirect>
        )
    } else {
        return (
            <div id="login">
                <img src={BigLogo} className="biglogo" alt="biglogo" />
                <p>Artjab Artists' Backffice</p>
                <h1>Login</h1>
                <form autoComplete="on">
                    <label htmlFor="email">email</label><br />
                    <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="username" placeholder="email"></input>{error_email}
                    <br />
                    <label htmlFor="password">password</label><br />
                    <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password" placeholder="password"></input>{error_password}
                    <br /><br />
                    <button type="submit" onClick={onSubmit}>Login</button>
                </form>
                <p> - or -</p>
                <NavLink to="/register">Register</NavLink>

            </div>
        )
    }
}

export default Login;