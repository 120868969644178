import React from 'react';
import Ex1 from '../images/Ex1.png'
import Ex2 from '../images/Ex2.png'
import Ex3 from '../images/Ex3.png'
import RP1 from '../images/RP1.png'
import RP4 from '../images/RP4.png'
import R5 from '../images/R5.png'
import RP6 from '../images/RP6.png'

function MarginsHowTo() {

    return (
        <React.Fragment>
            <h1>Artist's Margins How To</h1>
            <p>As a selling artist, it&rsquo;s important to get familiar with the way pricing and payments work in the ArtJab Marketplace. The following article should shed some light on topics like:</p>
            <h2>Base Price, Artist Margin, &amp; Retail Price</h2>
            <p>This is how it works.</p>
            <p><img alt="ex1" src={Ex1} /></p>
            <p>Let&rsquo;s break it down.</p>
            <p><strong>Base Price </strong></p>
            <p>The Base Price covers ArtJab&rsquo;s service fee and the cost of manufacturing.</p>
            <p>Base prices vary because they rely on delivery address. This is to take advantage of local production, material costs, and taxes relative to various artists&rsquo;, production, and delivery countries. Changing your delivery address at checkout could very well change the total.</p>
            <p>Please note that currency and cost constantly fluctuate, so base prices can alter occasionally.</p>
            <p><strong>Artist Margin (&amp; Markup) </strong></p>
            <p>The Artist Margin (&amp; Markup) show how much you make off of a sale, the markup being the percentage of the base price and the margin being the actual dollar amount.</p>
            <p><img alt="ex2" src={Ex2} /></p>
            <p>This defaults to 20% but you are free to raise or lower that amount any time in the Product Pricing section of your Account Details page.</p>
            <p><strong>Retail Price</strong></p>
            <p>The base price and artist margin combine to create the retail price, which is the price you see per product across the ArtJab website.</p>
            <p>Let&rsquo;s use an example.</p>
            <p><img alt="rp1" src={RP1} /></p>
            <p>Large framed art prints going to the US have a base price of <strong>US$125.00</strong>. <br />You set a 20% markup for large framed art prints. 20% of the $125 base prices means you get an <strong>artist margin</strong> of <strong>$25.00</strong>. <br />$125 base price + $25 artist margin makes for <strong>$150 retail price</strong>.</p>
            <h2>Sales and Discounts</h2>
            <p>So how do sales and discounts fit into the equation?</p>
            <p>Remember, your markup is a percentage of the base price, meaning if the base price changes, your artist margin changes. The visual equation looks something like this:</p>
            <p><img alt="ex3" src={Ex3} /></p>
            <p>Some of our products have bulk discounts that can affect the base price of items in larger orders. Let&rsquo;s look at another example to see how this affects your margin.</p>
            <p><img alt="rp4" src={RP4} /></p>
            <p>Let&rsquo;s say stickers to the US have a base price of <strong>US$2.00</strong></p>
            <p>You set a markup of <strong>50%</strong>, which means your margin is <strong>$1.00</strong>. The retail price for one sticker is then <strong>$3.00</strong>.</p>
            <p><img alt="rp5" src={R5} /></p>
            <p>Now, let&rsquo;s assume the volume discount for stickers is <strong>50%</strong> off the base price for 5+ stickers. This means that if a customer buys 5 or more stickers, then the new base price is <strong>$1.00</strong>.</p>
            <p>If you kept your markup at 50%, then 50% of $1.00 is 50 cents. The new base price (<strong>$1.00</strong>) plus the new artist margin (<strong>50 cents</strong>) means the new retail price is <strong>$1.50</strong>.</p>
            <p><img alt="rp6" src={RP6} /></p>
            <p>At the end of the 5 sticker sale, costs and payments (before taxes) round up to:</p>
            <p><br />Customer pays $1.50 per sticker, meaning they paid <strong>$7.50</strong> total.<br />The total base price after the discount was <strong>$5.00</strong>.<br />You receive <strong>50%</strong> of the base price, meaning you get <strong>$2.50</strong>.</p>
            <p>Though site-wide discounts might feel like you&rsquo;re making less profit, it&rsquo;s good to keep in mind that discounts drive customers to the site and attract them to your work.</p>
            <h2>GST, VAT and Taxes</h2>
            <p>ArtJab is an online marketplace where artists can sell their products to customers using the ArtJab platform. ArtJab collects proceeds on behalf of the artist and deducts ArtJab&rsquo;s service fee, shipping costs and the supplier&rsquo;s manufacturing fee, before paying the remaining funds to the artist (Artist Margin).</p>
            <p>Tax is included in orders where applicable, and may include sales tax, GST, or VAT. Because taxes vary around the world and based on personal circumstances, it is the responsibility of all artists on ArtJab to make sure they are handling their personal taxes correctly. This is further outlined in our Services Agreement.</p>
            <p>Depending on your account settings, base prices may include GST for Australian sales, resulting in your marked-up prices being different. We recommend that you seek professional advice on whether you should or should not register for GST, and update your tax settings accordingly.</p>
            <p>United Kingdom VAT is charged for sales in the European Union. ArtJab collects and remits UK VAT for both the base price and shipping costs to the HMRC. If you are registered for VAT then you can provide your VAT number in the tax section of your payment settings and we will include the VAT margin on your Artist Margin. If you are not sure if you need a VAT number we recommend speaking with a tax professional.</p>
        </React.Fragment>
    )
}

export default MarginsHowTo;