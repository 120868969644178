import React, { useState } from "react";
import User from '../api/User';
import { Redirect } from "react-router-dom";
import BigLogo from '../images/logo801x801.png';

function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirm] = useState("");
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [errors, setErrors] = useState([]);
    const error_email = errors.email ? <div><span className="error">{errors.email[0]}</span></div> : null;
    const error_password = errors.password ? <div><span className="error">{errors.password[0]}</span></div> : null;

    const onSubmit = async (e) => {
        e.preventDefault();
        User.register({ email, password, password_confirmation })
            .then(() => {
                setRedirectToLogin(true);
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    if (redirectToLogin) {
        return (
            <Redirect to='/login'></Redirect>
        )
    } else {
        return (
            <div id="register">
                <img src={BigLogo} className="biglogo" alt="logo" />
                <p>Artjab Artists' Backffice</p>
                <h1>Register</h1>
                <form autoComplete="on">
                    <label htmlFor="name">email </label><br />
                    <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="username"></input>
                    {error_email}
                    <br />
                    <label htmlFor="password">password </label><br />
                    <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new_password"></input>{error_password}
                    <br />
                    <label htmlFor="password_confirmation">password confirm </label><br />
                    <input type="password" id="password_confirmation" name="password_confirmation" value={password_confirmation}
                        onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete="new_password"></input>
                    <br /><br />
                    <button type="submit" onClick={onSubmit}>Register</button>
                </form>
            </div>
        )
    }
}

export default Register;