import React from 'react';
import ema from '../images/ema.jpg';
import lpp from '../images/lpp.jpg';
import hpr from '../images/hpr.jpg';
function PrintMaterials() {
    return (
        <React.Fragment>
            <h1>Print Materials</h1>
            <div id="print-materials">
                <div>
                    <img src={ema}></img>
                    <div>
                        <strong>Enhanced Matte Art (EMA) 200gsm</strong>
                        <p>
                            A premium-quality heavyweight fine art print material
                            with a smooth, clean finish. This museum-quality paper
                            is extremely consistent and works perfectly with large,
                            full colour graphics or illustrations. The matte finish
                            emphasises different highlights and tones in the
                            source artworks; helping to create stunning works of
                            art.
                    </p>
                    </div>
                </div>
                <div>
                    <img src={lpp}></img>
                    <div>
                        <strong>Lustre Photo Paper (LPP) 240gsm</strong>
                        <p>
                            A premium photographic paper with a satin lustre
                            finish. Preferred by professional photographers, the
                            lustre finish provides a subtle pearl-like texture.
                            Supporting deeper colour-saturation than matte
                            papers, this paper produces impressive colour depth
                            and strikingly intense blacks.
                    </p>
                    </div>
                </div>
                <div>
                    <img src={hpr}></img>
                    <div>
                        <strong>Hahnemühle Photo Rag (HPR) 308gsm</strong>
                        <p>
                            A heavy-duty matte paper made of 100% cotton rag
                            with a natural white tone and excellent black saturation.
                            Popular with professional photographers, this paper
                            produces very high-quality print reproductions.
                    </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PrintMaterials;