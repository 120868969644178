import React, { useEffect, useState, useRef } from 'react';
import User from '../api/User';
import Tools from '../api/Tools';
import Helpers from '../components/Helpers'
function Agreements() {
    const [agreements, setAgreements] = useState([]);
    const [acceptedAgreements, setAcceptedAgreements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = () => {
        setIsLoading(true);
        Tools.agreements().then((res) => {
            setAgreements(res.data);
        }).then(() => {
            User.accepted_agreements().then((res) => {
                setAcceptedAgreements(res.data);
                setIsLoading(false);
            })
        });
    }
    useEffect(() => {
        loadData();
    }, [])

    const acceptAgrrement = (id, agreement_type, version, date_issued) => {
        const message = `Are you sure you want to accept the ${agreement_type} agreement, version ${version}, issued at ${date_issued}, with id ${id}?`
        const confirm = window.confirm(message)
        if (confirm) {
            User.accept_agreement(id);
            loadData();
        }

    }

    const renderTableData = () => {
        return agreements.map((item, index) => {
            const { id, agreement_type, version, created_at } = item
            let dateAccepeted;
            acceptedAgreements.forEach((item, index) => {
                if (item.id === id) dateAccepeted = item.date_accepted;
            });
            return (
                <div className="agreement-row" key={index}>
                    <span>{id}</span>
                    <span>{agreement_type}</span>
                    <span>{version}</span>
                    <span>{Helpers.renderMySqlToUs(created_at)}</span>
                    <span>{dateAccepeted ? Helpers.renderMySqlToUs(dateAccepeted) : <span style={{ color: 'red' }}>not accepted</span>}</span>
                    <span>
                        <a href={"\\agreements\\" + agreement_type + version + ".pdf"} target="_blank" rel="noopener noreferrer">Read</a>&nbsp;&nbsp;
                        <span className={dateAccepeted ? "disabled" : "likeA"} onClick={() => {
                            if (!dateAccepeted) acceptAgrrement(id, agreement_type, version, Helpers.renderMySqlToUs(created_at))
                        }}
                        >Accept</span>
                    </span>
                </div>
            )
        })
    }


    if (isLoading) {
        return (
            <React.Fragment>
                <h1>Agreements</h1>
                <p>loading...</p>
            </React.Fragment>
        )
    } else if (agreements.length > 0) {
        return (
            <React.Fragment>
                <h1>Agreements</h1>
                <div id="agreements">
                    <div className="agreement-row header">
                        <span>Id</span>
                        <span>Agreement Type</span>
                        <span>Version</span>
                        <span>Date Issued</span>
                        <span>Date Accepted</span>
                        <span>Actions</span>
                    </div>
                    {renderTableData()}
                </div>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <h1>Agreements</h1>
                <div id="agreements">
                    <p>loading...</p>
                </div>
            </React.Fragment>
        )
    }
}

export default Agreements;