import React from "react";

function Home() {
    return (
        <div id="homepage">
            <div className="background"></div>
            <h1>Artist's Backoffice</h1>
            <p><strong>welcome</strong></p>
            <small style={{ backgroundColor: 'orange', color: '#964500', padding: '5px' }}>If you are experiencing any problems please logout and login again</small>
        </div>
    )
}

export default Home;